import { Navigate, Route, Routes } from 'react-router-dom';

import { AccountTab, NotificationTab, ProfileTab } from 'components/creator/setting';

export default function CreatorSettingsView() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <Routes>
          <Route path={`profile`} element={<ProfileTab />} />
          <Route path={`account`} element={<AccountTab />} />
          <Route path={`notifications`} element={<NotificationTab />} />
          <Route index element={<Navigate to={`account`} />} />
        </Routes>
      </div>
    </div>
  );
}
