// 3p
import { Outlet, useNavigate, useParams } from 'react-router-dom';

// app
import { ChatListItem, NotificationPermissionAlert } from 'components/common/chat';
import { useGetAllThread } from 'api/chat';

import { Loader } from 'components/common';
import { UIEvent, useCallback, useEffect, useMemo } from 'react';
import { sortBySentAt } from 'utils';
import { useSocket } from 'hooks';

export const ChatList = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();

  const { getAllThreadQuery } = useGetAllThread();
  const {
    data: threadsQueryResult,
    isLoading,
    hasPreviousPage,
    fetchPreviousPage,
    isFetchingPreviousPage,
  } = getAllThreadQuery;

  const threads = useMemo(() => {
    if (!threadsQueryResult) return [];
    return sortBySentAt(threadsQueryResult.pages.flatMap(({ data }) => data));
  }, [threadsQueryResult]);

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

      if (scrollTop + clientHeight === scrollHeight) {
        if (!isFetchingPreviousPage && hasPreviousPage) {
          fetchPreviousPage();
        }
      }
    },
    [fetchPreviousPage, hasPreviousPage, isFetchingPreviousPage]
  );

  const handleOnClick = useCallback(
    (uuid: string) => {
      navigate(`/app/chat/${uuid}`);
    },
    [navigate]
  );

  const { emit } = useSocket();
  const { updateReadStatus } = useGetAllThread();

  useEffect(() => {
    if (!chatId) return;
    if (!threads.length) return;

    const thread = threads.find(({ uuid }) => uuid === chatId);

    if (thread) {
      const { hasNewMessage, message } = thread;
      if (hasNewMessage) {
        const { uuid: muuid } = message;
        emit('chat:read', { tuuid: chatId, muuid });
        updateReadStatus(chatId, false);
      }
    }
  }, [chatId, threads, updateReadStatus, emit]);

  return (
    <>
      <main className="flex flex-auto bg-white border-t-2 h-chat-mobile md:h-chat-desktop">
        {/* 
        <div className="flex flex-auto"> */}
        <section
          onScroll={handleScroll}
          className={`overflow-y-auto sm:border-r-2 w-full sm:w-[400px] ${
            chatId ? 'hidden sm:block' : ''
          }`}
        >
          <NotificationPermissionAlert />
          {/* <ChatThreadSearch /> */}
          {threads ? (
            threads.map((thread) => {
              const { uuid } = thread;
              return <ChatListItem key={uuid} onClick={handleOnClick} {...thread} />;
            })
          ) : isLoading ? (
            <Loader />
          ) : (
            <h2>Non sono presenti conversazioni</h2>
          )}

          {isFetchingPreviousPage ? (
            <div className="py-8">
              <Loader />
            </div>
          ) : null}
        </section>

        <section className={`flex flex-col flex-auto ${chatId ? '' : 'hidden sm:block'}`}>
          <Outlet />
        </section>
        {/* </div> */}
      </main>
    </>
  );
};
