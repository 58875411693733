import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// 3p
import { Dialog } from '@headlessui/react';

// app

import { ModalLayout } from 'components/common';
import { useSocket } from 'hooks';

type IArrowFun = (...args: any[]) => void;

interface IErrorCallModal {
  message?: string;
  open: boolean;
  onClose: IArrowFun;
}

export const ErrorCallModal = (props: IErrorCallModal) => {
  const { message, open, onClose } = props;

  return (
    <ModalLayout open={open} onClose={() => {}} isLoading={false}>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
            Attenzione
          </Dialog.Title>
          <h2>{message}</h2>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Chiudi
        </button>
      </div>
    </ModalLayout>
  );
};

interface IIncomingCallModalProps {
  displayName?: string;
  open: boolean;
  onAccept: IArrowFun;
  onReject: IArrowFun;
  onClose: IArrowFun;
}

const RINGTONE_AUDIO_ASSET = new Audio('/assets/ringtone.mp3');

export const IncomingCallModal = (props: IIncomingCallModalProps) => {
  const { displayName, open, onAccept, onReject } = props;

  const ringtoneRef = useRef<HTMLAudioElement>(RINGTONE_AUDIO_ASSET);

  useEffect(() => {
    if (open) {
      ringtoneRef.current.play();
    } else {
      ringtoneRef.current.pause();
    }
  }, [open]);

  return (
    <ModalLayout open={open} onClose={() => {}} isLoading={false}>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
            Richiesta chiamata
          </Dialog.Title>
          <h2>
            Nuova chiamata in arrivo da <b>{displayName}</b>
          </h2>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onAccept}
        >
          Accetta
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onReject}
        >
          Rifiuta
        </button>
      </div>
    </ModalLayout>
  );
};

interface IOutgoingCallModalProps {
  open: boolean;
  onCancel: IArrowFun;
  onClose: IArrowFun;
}

export const OutgoindCallModal = (props: IOutgoingCallModalProps) => {
  const { open, onCancel } = props;

  const ringtoneRef = useRef<HTMLAudioElement>(RINGTONE_AUDIO_ASSET);

  useEffect(() => {
    if (open) {
      ringtoneRef.current.play();
    } else {
      ringtoneRef.current.pause();
    }
  }, [open]);

  return (
    <ModalLayout open={open} onClose={() => {}} isLoading={false}>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
            Chiamata in corso
          </Dialog.Title>

          <h2>Connessione in corso</h2>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onCancel}
        >
          Annulla
        </button>
      </div>
    </ModalLayout>
  );
};

/** CONTEXT */

interface IMeetingModalContext {
  showIncomingCall: (
    acceptCb: IArrowFun,
    rejectCb: IArrowFun,
    displayName?: string
  ) => void;
  closeIncomingCall: () => void;
  showOutcomingCall: (onAnswerCb: IArrowFun, cancelCb: IArrowFun) => void;
  closeOutcomingCall: () => void;
  showErrorCall: (message: string, cancelCb?: IArrowFun) => void;
  closeErrorCall: () => void;
  isOpenOutcomingModal: boolean;
  isOpenIncomingModal: boolean;
}

interface IMeetingModalContextProvider {
  children: JSX.Element | JSX.Element[];
}

const MeetingModalContext = createContext<IMeetingModalContext>(
  {} as IMeetingModalContext
);

export function MeetingModalContextProvider({
  children,
}: IMeetingModalContextProvider): JSX.Element {
  // INCOMING MODAL
  const [displayName, setDisplayName] = useState<string>();
  const [isOpenIncomingModal, setIsOpenIncomingModal] = useState<boolean>(false);

  const acceptCbRef = useRef<IArrowFun>();
  const rejectCbRef = useRef<IArrowFun>();

  const showIncomingCall = useCallback(
    (acceptCb: IArrowFun, rejectCb: IArrowFun, displayName?: string) => {
      acceptCbRef.current = acceptCb;
      rejectCbRef.current = rejectCb;

      setIsOpenIncomingModal(true);
      setDisplayName(displayName);
    },
    []
  );

  const closeIncomingCall = useCallback(() => {
    acceptCbRef.current = undefined;
    rejectCbRef.current = undefined;

    setIsOpenIncomingModal(false);
    setDisplayName(undefined);
  }, []);

  const incomingCallAccept = useCallback(() => {
    if (acceptCbRef.current) {
      acceptCbRef.current();
    }

    closeIncomingCall();
  }, [closeIncomingCall]);

  const incomingCallReject = useCallback(() => {
    if (rejectCbRef.current) {
      rejectCbRef.current();
    }

    closeIncomingCall();
  }, [closeIncomingCall]);

  const { on, off } = useSocket();

  // OUTCOMING MODAL
  const [isOpenOutcomingModal, setIsOpenOutcomingModal] = useState<boolean>(false);

  const answerCbRef = useRef<IArrowFun>();
  const cancelCbRef = useRef<IArrowFun>();

  useEffect(() => {
    if (!isOpenOutcomingModal) return;

    const f = (...args: any[]) => {
      if (answerCbRef.current) {
        answerCbRef.current(...args);
      }
    };

    on('meeting:onanswer', f);

    return () => {
      off('meeting:onanswer', f);
    };
  }, [isOpenOutcomingModal, on, off]);

  const showOutcomingCall = useCallback((answerCb: IArrowFun, cancelCb: IArrowFun) => {
    answerCbRef.current = answerCb;
    cancelCbRef.current = cancelCb;

    setIsOpenOutcomingModal(true);
  }, []);

  const closeOutcomingCall = useCallback(() => {
    answerCbRef.current = undefined;
    cancelCbRef.current = undefined;

    setIsOpenOutcomingModal(false);
  }, []);

  const outcomingCallCancel = useCallback(() => {
    if (cancelCbRef.current) {
      cancelCbRef.current();
    }

    closeOutcomingCall();
  }, [closeOutcomingCall]);

  // Error Modal
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();

  const showErrorCall = useCallback((message: string, cancelCb?: IArrowFun) => {
    cancelCbRef.current = cancelCb;

    setMessage(message);
    setIsOpenErrorModal(true);
  }, []);

  const closeErrorCall = useCallback(() => {
    cancelCbRef.current = undefined;
    setMessage(undefined);
    setIsOpenErrorModal(false);
  }, []);

  return (
    <MeetingModalContext.Provider
      value={{
        showIncomingCall,
        closeIncomingCall,
        showOutcomingCall,
        closeOutcomingCall,
        isOpenOutcomingModal,
        isOpenIncomingModal,
        showErrorCall,
        closeErrorCall,
      }}
    >
      {children}
      <ErrorCallModal
        open={isOpenErrorModal}
        message={message}
        onClose={closeErrorCall}
      />
      <IncomingCallModal
        displayName={displayName}
        open={isOpenIncomingModal}
        onAccept={incomingCallAccept}
        onReject={incomingCallReject}
        onClose={closeIncomingCall}
      />
      <OutgoindCallModal
        open={isOpenOutcomingModal}
        onCancel={outcomingCallCancel}
        onClose={closeOutcomingCall}
      />
    </MeetingModalContext.Provider>
  );
}

export function useMeetingModal() {
  return useContext(MeetingModalContext);
}
