import { useCallback } from 'react';
import { useNotification } from 'hooks';
import { useState, useEffect } from 'react';
import APIClient from 'api/ApiClient';
import { ENV_NOTIFICATION } from 'config';
import { Loader } from 'components/common';
import { isPWA } from 'utils'; // Update the import path for the utility function

const NotificationTab = () => {
  const {
    hasPermission,
    isSubscribed,
    requestPermission,
    handleSubscribe,
    handleUnsubscribe,
  } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSwitchOn, setIsSwitchOn] = useState(isSubscribed);
  const [isPWAApp, setIsPWAApp] = useState(false);

  useEffect(() => {
    setIsPWAApp(isPWA());
  }, []);

  useEffect(() => {
    setIsSwitchOn(isSubscribed);
  }, [isSubscribed]);

  useEffect(() => {
    const token = localStorage.getItem('push-subscription-token');

    if (!token) return;

    const fetchSubscription = async () => {
      setIsLoading(true);

      try {
        const { data } = await APIClient.get<{ status: string }>(
          ENV_NOTIFICATION.GET_PUSH_SUBSCRIPTION(token)
        );

        const { status } = data;

        setIsSwitchOn(status === 'ACTIVE');
      } catch (error) {
        console.error('Failed to fetch subscription:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  const handleToggle = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      if (isSwitchOn) {
        await handleUnsubscribe();
      } else {
        await handleSubscribe();
      }
      setIsSwitchOn(!isSwitchOn);
    } catch (err) {
      setError('Si è verificato un errore. Riprova più tardi.');
      console.error('Notification error:', err);
    } finally {
      setIsLoading(false);
    }
  }, [isSwitchOn, handleSubscribe, handleUnsubscribe]);

  const handleRequestPermission = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      await requestPermission();
      await handleToggle();
    } catch (err) {
      setError('Si è verificato un errore. Riprova più tardi.');
      console.error('Notification error:', err);
    } finally {
      setIsLoading(false);
    }
  }, [requestPermission, handleToggle]);

  /* useEffect(() => {
    if(!hasPermission) {
      if (result !== 'granted') {
        setError(
          'Per ricevere notifiche, abilita i permessi dalle impostazioni del browser'
        );
      }
    }
  }, [hasPermission]) */

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4">
          <h1 className="text-3xl font-bold text-center mb-6">Impostazioni Notifiche</h1>
          {error && <p className="text-red-500 text-center">{error}</p>}
          {!hasPermission ? (
            <div className="text-center">
              <p className="mb-4">
                Per abilitare le notifiche, è necessario concedere i permessi dalle
                impostazioni del browser. Se hai rifiutato i permessi la prima volta, devi
                abilitarli manualmente.
              </p>

              <button
                onClick={handleRequestPermission}
                disabled={isLoading}
                className={`ml-auto mr-auto px-6 py-3 rounded bg-pink-500 hover:bg-pink-600 text-white transition-colors flex items-center justify-center sm:block ${isPWAApp ? 'block' : 'hidden'}`}
              >
                {isLoading ? <Loader className="h-6 w-6" /> : null}
                Abilita Permessi Notifiche
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-center mb-4">
              <span className="mr-4 text-lg">Abilita Notifiche</span>
              <button
                onClick={handleToggle}
                disabled={isLoading}
                className={`relative inline-flex items-center h-8 rounded-full w-16 transition-colors ${
                  isSwitchOn ? 'bg-pink-600' : 'bg-gray-300'
                }`}
              >
                <span
                  className={`transform transition-transform ${
                    isSwitchOn ? 'translate-x-8' : 'translate-x-1'
                  } inline-block w-6 h-6 bg-white rounded-full`}
                />
              </button>
              {isLoading && <Loader containerStyle="ml-2" className="h-6 w-6" />}
            </div>
          )}
          <div className="mt-6 p-4 bg-yellow-100 border-1 border-yellow-500 text-yellow-700 sm:hidden block">
            <h2 className="text-xl font-semibold mb-2">Nota per i dispositivi mobile</h2>
            <p>
              Se stai utilizzando un dispositivo mobile, è necessario aggiungere questa
              applicazione alla schermata home per abilitare le notifiche push. Segui
              questi passaggi:
            </p>
            <ol className="list-decimal list-inside mt-2">
              <li>
                Apri il menu di condivisione del browser (icona con una freccia verso
                l'alto).
              </li>
              <li>Seleziona "Aggiungi a Home".</li>
              <li>Conferma l'aggiunta dell'applicazione alla schermata home.</li>
            </ol>
            <p className="mt-2">
              Una volta aggiunta l'applicazione alla schermata home, riaprila da lì per
              abilitare le notifiche push.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationTab;
