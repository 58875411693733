// 3p
import { QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

// app
import { AppRouter } from 'views/app';
import { WebRouter } from 'views/web';
import { AdminRouter } from 'views/admin';

import {
  ToastContextProvider,
  AuthContextProvider,
  ChatContextProvider,
  MeetingContextProvider,
  SocketContextProvider,
  NotificationContextProvider,
} from 'hooks';
import { MeetingModalContextProvider } from 'components/common/chat/video';

import ScrollToTop from 'components/common/ScrollToTop';
import AdultConsentModal from 'components/common/AdultConsentModal';

import { PrivateRoute } from 'utils';

import * as CONFIG from './config/Config';

import { MainLayout } from 'layouts/web';

import './App.css';
import { useCallback, useEffect } from 'react';

import { GTM_AUTH, GTM_ID, GTM_PREVIEW } from 'config/Enviroment';

import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { useCookies } from 'react-cookie';
import { QUERY_CLIENT } from 'libs';
import { clarity } from 'react-microsoft-clarity';

if (!CONFIG.IS_DEV) {
  clarity.init('pujz3sr58d');
}

function App() {
  const [cookies] = useCookies(['vieniora-com-privacy']);

  const enableAnalytics = useCallback(() => {
    if (GTM_ID) {
      TagManager.dataLayer({
        dataLayer: [
          'consent',
          'update',
          {
            ad_storage: 'granted',
            analytics_storage: 'granted',
          },
        ],
      });
    }

    if (clarity.hasStarted()) {
      clarity.consent();
    }
  }, []);

  const declineAnalytics = useCallback(() => {
    if (GTM_ID) {
      TagManager.dataLayer({
        dataLayer: [
          'consent',
          'update',
          {
            ad_storage: 'denied',
            analytics_storage: 'denied',
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (GTM_ID) {
      const tagManagerArgs = {
        gtmId: GTM_ID,
        auth: GTM_AUTH,
        preview: GTM_PREVIEW,
        consentSettings: {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        },
      } as TagManagerArgs;

      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  useEffect(() => {
    const enabledAnalytics = cookies['vieniora-com-privacy'];

    if (enabledAnalytics) {
      enableAnalytics();
    }
  }, [cookies, enableAnalytics]);

  return (
    <>
      <ScrollToTop />
      <QueryClientProvider client={QUERY_CLIENT}>
        <NotificationContextProvider>
          <AuthContextProvider>
            <ToastContextProvider>
              <SocketContextProvider>
                <ChatContextProvider>
                  <MeetingModalContextProvider>
                    <MeetingContextProvider>
                      <Routes>
                        <Route element={<PrivateRoute />}>
                          <Route path="/admin/*" element={<AdminRouter />} />
                        </Route>
                        <Route element={<PrivateRoute />}>
                          <Route path="/app/*" element={<AppRouter />} />
                        </Route>
                        <Route element={<MainLayout />}>
                          <Route path="/*" element={<WebRouter />} />
                        </Route>
                      </Routes>
                    </MeetingContextProvider>
                  </MeetingModalContextProvider>
                </ChatContextProvider>
              </SocketContextProvider>
            </ToastContextProvider>
          </AuthContextProvider>
        </NotificationContextProvider>
      </QueryClientProvider>
      <AdultConsentModal />
      <CookieConsent
        {...CONFIG.PRIVACY_COOKIE_PROPS}
        onAccept={enableAnalytics}
        onDecline={declineAnalytics}
      >
        {CONFIG.PRIVACY_COOKIE_MESSAGE}
      </CookieConsent>
    </>
  );
}

export default App;
