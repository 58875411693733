import { useCallback, useEffect, useState, createContext, useMemo } from 'react';
import APIClient from 'api/ApiClient';

import WebPush from 'utils/WebPush';
import { ENV_NOTIFICATION } from 'config';

/* function getOSName() {
  const userAgent = window.navigator.userAgent;
  if (/Windows/i.test(userAgent)) return 'Windows';
  if (/Mac/i.test(userAgent)) return 'MacOS';
  if (/X11/i.test(userAgent)) return 'UNIX';
  if (/Linux/i.test(userAgent)) return 'Linux';
  if (/Android/i.test(userAgent)) return 'Android';
  if (/iPhone|iPad|iPod/i.test(userAgent)) return 'iOS';
  return 'Unknown';
} */

interface INotificationContext {
  isSubscribed: boolean;
  hasPermission: boolean;
  requestPermission: () => Promise<void>;
  handleSubscribe: () => Promise<void>;
  handleUnsubscribe: () => Promise<void>;
}
const notificationContext = createContext<INotificationContext>(
  {} as INotificationContext
);

export function NotificationContextProvider({ children }: { children: JSX.Element }) {
  const auth = useNotification();
  return (
    <notificationContext.Provider value={auth}>{children}</notificationContext.Provider>
  );
}

interface SubscriptionPayload {
  token: PushSubscription;
  userAgent: string;
  status: 'ACTIVE' | 'USER_DEACTIVATE';
}

const validateNotificationPrerequisites = () => {
  WebPush.validateNotificationCapability();

  if (!WebPush.hasPermission()) {
    throw new Error('Notifications are not enabled for this browser');
  }
};

export function useNotification() {
  const [hasPermission, setHasPermission] = useState(false);
  const [subscription, setSubscription] = useState<PushSubscription | null>(null);

  const isSubscribed = useMemo(() => !!subscription, [subscription]);

  const checkSubscriptionStatus = useCallback(async () => {
    try {
      const subscription = await WebPush.getSubscription();
      setSubscription(subscription);
    } catch (error) {
      console.error('Failed to check subscription status:', error);
      setSubscription(null);
    }
  }, []);

  const deactivatePushNotifications = useCallback(async () => {
    validateNotificationPrerequisites();

    const token = await WebPush.getSubscription();

    if (!token) {
      throw new Error('Token not found');
    }

    try {
      const { userAgent } = navigator;
      const payload: SubscriptionPayload = {
        token,
        userAgent,
        status: 'USER_DEACTIVATE',
      };

      await APIClient.post(ENV_NOTIFICATION.SAVE_PUSH_SUBSCRIPTION, payload);

      // Is disabled due to the fact that the user is logged out
      // await WebPush.unsubscribe();

      setSubscription(null);
    } catch (error) {
      console.error('WebPush handlePermission', error);
    }
  }, []);

  const enablePushNotifications = useCallback(async () => {
    validateNotificationPrerequisites();

    try {
      let subscription = await WebPush.getSubscription();
      if (!subscription) {
        subscription = await WebPush.subscribe();
      }

      const { userAgent } = navigator;
      const payload: SubscriptionPayload = {
        token: subscription,
        userAgent,
        status: 'ACTIVE',
      };

      const { data } = await APIClient.post<{ token: string }>(
        ENV_NOTIFICATION.SAVE_PUSH_SUBSCRIPTION,
        payload
      );

      const { token } = data;

      if (token) {
        localStorage.setItem('push-subscription-token', token);
      }

      setSubscription(subscription);
      console.log('Notifiche abilitate con successo');
    } catch (error) {
      setSubscription(null);
      console.error('WebPush handlePermission', error);
    }
  }, []);

  const requestPermission = useCallback(async () => {
    WebPush.validateNotificationCapability();

    if (WebPush.hasPermission()) {
      throw new Error('Notifiche già abilitate');
    }

    const permission = await Notification.requestPermission();
    setHasPermission(permission === 'granted');
  }, []);

  // Controlla lo stato iniziale di permesso e subscription
  useEffect(() => {
    let mounted = true;

    const initialize = async () => {
      if ('Notification' in window && mounted) {
        // Imposta il permesso corrente
        const hasPermission = WebPush.hasPermission();
        setHasPermission(hasPermission);

        // Controlla se esiste già una subscription
        await checkSubscriptionStatus();
      }
    };

    initialize();

    return () => {
      mounted = false;
    };
  }, [checkSubscriptionStatus]);

  /*const registraServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Service Worker registrato:', registration);

        // Ottieni subscription
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            process.env.REACT_APP_VAPID_PUBLIC_KEY!
          ),
        });


        // Invia subscription al backend
        // await inviaSubscriptionAlBackend(subscription);
      } catch (error) {
        console.error('Errore registrazione Service Worker:', error);
      }
    }
  };*/

  return Object.freeze({
    isSubscribed,
    hasPermission,
    requestPermission,
    handleUnsubscribe: deactivatePushNotifications,
    handleSubscribe: enablePushNotifications,
  });
}
