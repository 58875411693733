// client/services/webPush
function urlBase64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

class WebPush {
  static validateNotificationCapability() {
    if (!('Notification' in window)) {
      throw new Error('Notifiche non supportate in questo browser');
    }
  }

  static hasPermission() {
    if (!('Notification' in window)) return false;
    return Notification.permission === 'granted';
  }

  static async requestPermission() {
    return await Notification.requestPermission();
  }

  static async getSubscription() {
    return await navigator.serviceWorker.ready.then(async (registration) => {
      return await registration.pushManager.getSubscription();
    });
  }

  static async subscribe() {
    const registration = await navigator.serviceWorker.ready;

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY!,
    });

    return subscription;
  }

  static async unsubscribe() {
    const subscription = await this.getSubscription();
    if (subscription) {
      await subscription.unsubscribe();
    }
    return subscription;
  }
}

export default WebPush;
