import { Fragment, useCallback, useMemo, useState } from 'react';

// app

// 3p
import { IChatMessage, IMedia2, IUser } from 'interfaces';
import { useAuth, useModal, useOnScrollTop } from 'hooks';
import { MediaModal } from '../media/MediaModal';
import { formatDateDistance } from 'utils';
import { MessageVideo } from './MessageVideo';
import { MessageAudio } from './MessageAudio';
import { MessagePhoto } from './MessagePhoto';

function messageGroup(messages: IChatMessage[]) {
  return messages.reduce((prev, curr) => {
    const { uuid, sender, sendAt } = curr;

    if (!prev.length) {
      const group = {
        uuid: uuid,
        sender: sender,
        messages: [curr],
        sendAt: sendAt,
      };

      return [group];
    }

    const prevGroup = prev[prev.length - 1];
    const { sender: prevSender } = prevGroup;

    // è lo stesso utente, quindi aggiungo il messaggio
    if (prevSender.id === sender.id) {
      prevGroup.messages.push(curr);
    } else {
      // è un altro utente
      const group = {
        uuid: uuid,
        sender: sender,
        messages: [curr],
        sendAt: sendAt,
      };
      return [...prev, group];
    }

    return prev;
  }, [] as IChatMessageGroup[]);
}

interface IChatMessageGroup {
  uuid: string;
  sender: Partial<IUser>;
  messages: IChatMessage[];
  sendAt: string;
  onViewImage?: (media: any) => void;
  onViewVideo?: (video: any) => void;
}

enum MessageDirection {
  OUT,
  IN,
}

const STYLES = {
  [MessageDirection.OUT]: {
    first:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-t-lg rounded-l-lg bg-pink-500 max-w-xs lg:max-w-md',
    center:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-l-lg bg-pink-500 max-w-xs lg:max-w-md',
    last: 'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-b-lg rounded-l-lg bg-pink-500 max-w-xs lg:max-w-md',
    group: 'flex flex-col items-end justify-center group',
    container: 'flex flex-row justify-end mt-3',
    body: 'messages text-sm text-white grid grid-flow-row gap-2',
  },
  [MessageDirection.IN]: {
    first:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-t-lg rounded-r-lg bg-gray-100 max-w-xs lg:max-w-md',
    center:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-r-lg bg-gray-100 max-w-xs lg:max-w-md',
    last: 'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-b-lg rounded-r-lg bg-gray-100 max-w-xs lg:max-w-md',
    group: 'flex flex-col items-start justify-center group',
    container: 'flex flex-row justify-start mt-3',
    body: 'messages text-sm text-gray-700 grid grid-flow-row gap-2',
  },
};

interface IChatMessageGroupProps extends IChatMessageGroup {
  playingId: string | null;
  setPlayingId: React.Dispatch<React.SetStateAction<string | null>>;
  showSenderUsername?: boolean;
}

const ChatMessageGroup = (props: IChatMessageGroupProps) => {
  const {
    sender,
    messages,
    onViewImage,
    onViewVideo,
    playingId,
    setPlayingId,
    showSenderUsername = false,
  } = props;

  const { user } = useAuth();

  const direction = useMemo(() => {
    if (user && user.id === sender.id) {
      return MessageDirection.OUT;
    }

    return MessageDirection.IN;
  }, [sender, user]);

  const onImageClick = useCallback(
    (id: string, url: string) => {
      if (!onViewImage) return;

      onViewImage({ id, url, photo: { url } });
    },
    [onViewImage]
  );

  const onVideoClick = useCallback(
    (id: string, url: string) => {
      if (!onViewVideo) return;

      onViewVideo({ id, video: { url } });
    },
    [onViewVideo]
  );

  return (
    <>
      {showSenderUsername ? (
        <div className="font-bold	text-lg text-left mb-2">{sender.username}</div>
      ) : null}
      <div className={STYLES[direction].container}>
        {/* AVATAR MINUSCOLO {direction === MessageDirection.IN && (
        <div className="w-8 h-8 relative flex flex-shrink-0 mr-4">
          <img
            className="shadow-md rounded-full w-full h-full object-cover"
            src="https://randomuser.me/api/portraits/women/33.jpg"
            alt=""
          />
        </div>
      )} */}
        <div className={STYLES[direction].body}>
          {messages.map((message, i) => {
            const { uuid, content, photo, video, audio, sendAt } = message;

            const isFirstMessage = i === 0;
            const isLastMessage = i === messages.length - 1;

            const messageStyle = isFirstMessage
              ? STYLES[direction].first
              : isLastMessage
                ? STYLES[direction].last
                : STYLES[direction].center;

            const { distance, seconds } = formatDateDistance(sendAt);
            const formattedsendAt = seconds > 180 ? distance : 'Poco fa';

            return (
              <div key={uuid} className={STYLES[direction].group}>
                <p className="mr-1 text-xs text-gray-400">{formattedsendAt}</p>

                {video ? (
                  <MessageVideo video={video} onVideoClick={onVideoClick} />
                ) : null}

                {photo ? (
                  <MessagePhoto photo={photo} onImageClick={onImageClick} />
                ) : null}

                {audio ? (
                  <MessageAudio
                    audio={audio}
                    id={uuid}
                    playingId={playingId}
                    setPlayingId={setPlayingId}
                  />
                ) : null}

                {content && <p className={messageStyle}>{content}</p>}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

interface IChatAreaContentProps {
  messages: IChatMessage[];
  showSenderUsername?: boolean;
  fetch: () => Promise<void>;
  isFetching: boolean;
}

export const ChatAreaContent = (props: IChatAreaContentProps) => {
  const { messages, fetch, showSenderUsername = false } = props;

  const [playingId, setPlayingId] = useState<string | null>(null);

  const {
    openModal: viewMediaModalOpen,
    closeModal: viewMediaModalClose,
    modalData: viewMediaModal,
    setData: viewMediaModalSetData,
  } = useModal<IMedia2 | any>();

  const handleOnViewImage = (image: any) => {
    viewMediaModalSetData(image);
    viewMediaModalOpen();
  };

  const handleOnViewVideo = (video: any) => {
    viewMediaModalSetData(video);
    viewMediaModalOpen();
  };

  const messagesGroupByUser = useMemo(() => {
    return messageGroup(messages);
  }, [messages]);

  const { chatBoxRef, handleChatBoxScroll } = useOnScrollTop({
    messages,
    onFetchNewMessages: fetch,
  });

  if (!messages) {
    return <>Non ci sono messaggi</>;
  }

  return (
    <>
      <MediaModal {...viewMediaModal} onClose={viewMediaModalClose} />
      <div
        className="flex-1 px-3 overflow-y-auto"
        ref={chatBoxRef}
        onScroll={handleChatBoxScroll}
      >
        {/* {isFetching ? <Loader /> : null} */}
        {messagesGroupByUser.map((group) => (
          <Fragment key={group.uuid}>
            <ChatMessageGroup
              showSenderUsername={showSenderUsername}
              onViewImage={handleOnViewImage}
              onViewVideo={handleOnViewVideo}
              playingId={playingId}
              setPlayingId={setPlayingId}
              {...group}
            />
          </Fragment>
        ))}
      </div>
    </>
  );
};
