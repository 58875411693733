import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { CookiesProvider } from 'react-cookie';

import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from 'utils/ScrollToTop';

const el = document.getElementById('root');
if (el === null) throw new Error('Root container missing in index.html');

const root = ReactDOM.createRoot(el);

root.render(
  // <React.StrictMode>
  <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </CookiesProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
