import { IFile, IMedia2 } from 'interfaces';
import { CDN_ENDPOINT } from 'config';

interface IUserImageProps {
  image?: IFile | null;
  alt: string;
  className?: string;
  onClick?: (media: IMedia2) => Promise<void>;
  data?: IMedia2;
  params?: {
    quality?: string;
    class?: 'small' | 'medium';
    width?: string;
    height?: string;
    aspect_ratio?: '1:1' | '16:9';
  };
}

const UserImagePlaceholder = (props: Partial<IUserImageProps>) => {
  const { className = 'h-full w-full' } = props;

  return (
    <svg className={className + ' text-gray-300'} fill="currentColor" viewBox="0 0 24 24">
      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
  );
};

const DEFAULT_PARAMS = { class: 'small' };

export const UserImage = (props: IUserImageProps) => {
  const { image, alt, className, onClick, data, params = DEFAULT_PARAMS } = props;

  if (!image || !image.url) {
    return <UserImagePlaceholder className={className} />;
  }

  const queryString = params ? '?' + new URLSearchParams(params).toString() : '';
  const url = `${CDN_ENDPOINT.STATIC}${image.url}${queryString}`;

  //onClick serviva quando ci ha fatto mettere che cliccavi sull'immagine e si apriva la modale con le foto profilo. Poi ha cambiato per metterlo come ora, quindi si potrebbe togliere

  return onClick && data ? (
    <img
      className={className}
      src={url}
      alt={alt}
      loading="lazy"
      onClick={() => onClick(data)}
    />
  ) : (
    <img className={className} src={url} alt={alt} loading="lazy" />
  );
};
