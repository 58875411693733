import { useNotification } from 'hooks';
import { Link } from 'react-router-dom';
import { CogIcon } from '@heroicons/react/outline';

export const NotificationPermissionAlert = () => {
  const { hasPermission } = useNotification();

  if (hasPermission) {
    return (
      <Link
        to="/app/settings/notifications"
        className="p-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 flex items-center"
      >
        <p className="mb-2 flex-1">Impostazioni notifiche</p>
        <div className="inline-block p-2 rounded bg-blue-500 hover:bg-blue-600 text-white transition-colors">
          <CogIcon className="h-6 w-6" />
        </div>
      </Link>
    );
  }

  if (!hasPermission) {
    return (
      <Link
        to="/app/settings/notifications"
        className="p-4 bg-pink-100 border-l-4 border-pink-500 text-pink-700 flex items-center"
      >
        <p className="mb-2 flex-1">
          Abilita le notifiche per ricevere avvisi in tempo reale direttamente sul tuo
          dispositivo.
        </p>
        <div className="inline-block p-2 rounded bg-pink-500 hover:bg-pink-600 text-white transition-colors">
          <CogIcon className="h-6 w-6" />
        </div>
      </Link>
    );
  }

  return null;
};
