import { useRef, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { useDeleteAccount } from 'api/user';
import { useAuth, useToast } from 'hooks';
import { ModalLayout } from 'components/common';
import WebPush from 'utils/WebPush';

interface IAccountDeleteModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const AccountDeleteModal = (props: IAccountDeleteModalProps) => {
  const { open, setOpen } = props;

  const cancelButtonRef = useRef(null);

  const { toastSuccess, toastError } = useToast();

  const { revokeUser } = useAuth();

  const { deleteAccountMutation } = useDeleteAccount();
  const { mutateAsync } = deleteAccountMutation;

  const onConfirm = async () => {
    try {
      await mutateAsync();
      revokeUser();

      toastSuccess({
        title: 'Oprazione completata',
        message: 'Il tuo account è stato rimosso correttamente',
      });
    } catch (error) {
      console.error(error);

      toastError({
        title: 'Operazione non completata',
        message: 'Contatta il supporto per maggiori informazioni',
      });
    }
  };

  return (
    <ModalLayout open={open} onClose={() => setOpen(false)}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Rimuovi account
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-md text-gray-800">
              Attenzione la rimozione dell'account non è un operazione reversibile.
            </p>
            <p className="mt-1 text-md text-gray-800">
              Confermando questo modulo richiederai l'eliminazione di tutti i tuoi dati
              dalla piattaforma in maniera DEFINITIVA.
            </p>
            <p className="mt-1 text-md text-gray-800">
              Di conseguenza, TUTTE le informazioni associate al tuo account, COMPRESO il
              saldo disponibile e non ancora ritirato andranno perse.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => onConfirm()}
        >
          Conferma
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Annulla
        </button>
      </div>
    </ModalLayout>
  );
};

export const AccountDeleteForm = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AccountDeleteModal open={open} setOpen={setOpen} />
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Disattiva account
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Puoi disattivare il tuo account in qualsiasi momento. Tuttavia, se cambi
              idea non sarà più possibile recuperarlo.
            </p>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            onClick={() => setOpen(true)}
            className="bg-red-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
          >
            Disattiva
          </button>
        </div>
      </div>
    </>
  );
};
