// 3p
import axios from 'axios';
import * as tus from 'tus-js-client';

// app
import APIClient from '../ApiClient';

export async function createUploadUrl(file: File) {
  const body = { fileType: file.type };

  const { data } = await APIClient.post<any>('/communications/createUploadUrl', body);

  return data;
}

export async function uploadFile(audioFile: File, updateProgress: (v: number) => void) {
  const { presignedUrl, fileName } = await createUploadUrl(audioFile);

  const config = {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const percentComplete = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      updateProgress(percentComplete);
    },
  };

  const options = {
    headers: {
      'Content-Type': audioFile.type,
      //'x-amz-acl': 'public-read',
    },
    ...config,
  };

  await axios.put(presignedUrl, audioFile, options);

  return { audioFile, fileName };
}

export async function uploadVideo(
  file: File,
  key: string,
  libraryId: string,
  videoId: string,
  expiration: string,
  updateProgress?: (v: number) => void
) {
  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(file, {
      endpoint: 'https://video.bunnycdn.com/tusupload',
      parallelUploads: 5,
      retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
      headers: {
        AuthorizationSignature: key, // SHA256 signature (library_id + api_key + expiration_time + video_id)
        AuthorizationExpire: expiration, // Expiration time as in the signature,
        VideoId: videoId, // The guid of a previously created video object through the Create Video API call
        LibraryId: libraryId,
      },
      metadata: {
        filetype: file.type,
        title: file.name,
        // collection: 'b9083ccf-973f-40a4-9cd6-80216d6edd56',
      },
      onError: function (error) {
        reject(error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentComplete = Math.round((bytesUploaded * 100) / bytesTotal);
        if (updateProgress) updateProgress(percentComplete);
      },
      onSuccess: function () {
        resolve(true);
      },
    });

    upload.start();
  });
}

export function useAttachment() {
  return { createUploadUrl, uploadFile };
}
