interface ILoaderProps {
  containerStyle?: string;
  className?: string;
  progress?: number;
}

export const Loader = (props: ILoaderProps) => {
  const { containerStyle = '', className = '', progress } = props;

  return (
    <div className={`flex justify-center items-center h-full ${containerStyle}`}>
      <div
        className={`animate-spin rounded-full h-12 w-12 border-b-2 border-pink-900 ${className}`}
      ></div>
      {progress !== undefined && (
        <div
          className="absolute text-pink-900 text-md font-bold"
          style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
        >
          {progress}%
        </div>
      )}
    </div>
  );
};

export default Loader;
